<template>
  <v-row>
    <!-- Start Single Service  -->
    <v-col
      xl="4"
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(item, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--3 text-white">
        <div class="icon">
          <img :src="item.src" alt="Creative Agency" />
        </div>
        <div class="content">
          <h3 class="heading-title">
            {{ item.title }}
          </h3>
          <p>
            {{ item.desc }}
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
</template>

<script>
  export default {
    data() {
      return {
        serviceContent: [
          {
            src: require("../../assets/images/icons/icon-01.png"),
            title: "ERP System",
            desc: `Enterprise Resource Planning is needed in every enterprise. We provide the best ERP System in the industry.`,
          },
          {
            src: require("../../assets/images/icons/icon-02.png"),
            title: "Web Development",
            desc: `Your website is the window of your business. We only develop top-notched websites for our customers.`,
          },
          {
            src: require("../../assets/images/icons/icon-03.png"),
            title: "Mobile Application Development",
            desc: `You have the next big idea. We have a talented and creative team with years of experience to develop it!`,
          },
        ],
      };
    },
  };
</script>
